<template>
  <div>
    <FlowResolver
      :flow="flow"
      :router="$router"
      :route="$route"
      :lang="$store.getters.language.lang"
      :callback="submit"
      :block-init="blockInit"
      @handle-show-loader="handleLoader($event)"
      v-if="!loading"
    />

    <template v-else>
      <!-- skeleton -->
    </template>
  </div>
</template>

<script>
import { Flow, FlowResolver } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
export default {
  name: 'Questionnaire',
  components: {
    FlowResolver
  },
  data () {
    let blockInit = null

    if (this.$route.query.blockInit === 'individual-therapy') {
      blockInit =
        'matching-questionnaire-service-psychology-individual-therapy-reasons'
    }

    if (this.$route.query.blockInit === 'individual-therapy-hide-prices') {
      blockInit = 'matching-questionnaire-service-psychology-individual-therapy'
    }

    if (this.$route.query.redirect) {
      blockInit = this.$route.query.redirect
    }

    return {
      flow: null,
      loading: true,
      blockInit
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.matching) {
        this.flow = this.matching
        this.loading = false
      } else {
        let matchingkey = 'MATCHING'
        const matchingQa = localStorage.getItem('matching-qa')

        if (matchingQa) {
          matchingkey = matchingQa
        }

        Flow.get(matchingkey)
          .then((response) => (this.flow = response))
          .catch((e) => console.error(e))
          .finally(() => (this.loading = false))
      }
    },
    submit ({ back, front }) {
      const service = back.blocks.find((block) => block.id === back.summary_id)
        .options[0].text.en

      return new Promise((resolve, reject) => {
        Flow.post(back, 'usr', this.matchingResult)
          .then((response) => {
            // safe flow
            this.$store.commit('flow/SET_MATCHING', front)

            // route and resolve promise
            this.$router
              .push({
                name: 'QuestionnaireResults',
                query: {
                  specialistsResult: JSON.stringify(response.result),
                  debugResult: JSON.stringify(response.debug),
                  service
                }
              })
              .then(() => resolve())
          })
          .catch((e) => reject(e))
      })
    },
    handleLoader (option) {
      if (option) {
        this.$loader.handleShowLoader({
          lang: this.$store.getters.language.lang,
          type: 'degrade',
          key: 'MATCHING'
        })
      } else {
        this.$loader.handleRemoveLoader()
      }
    }
  },
  computed: {
    ...mapGetters({
      matching: 'flow/matching',
      matchingResult: 'growthBook/matchingResult'
    })
  }
}
</script>
