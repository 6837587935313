<template>
  <div>
    <Questionnaire />
  </div>
</template>

<script>
import Questionnaire from './pages/Questionnaire.vue'

export default {
  name: 'QuestionnaireContainer',
  components: {
    Questionnaire
  }
}
</script>
